import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cp-main-inner__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_title_return = _resolveComponent("title-return")!
  const _component_loading = _resolveComponent("loading")!
  const _component_form_block = _resolveComponent("form-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_title_return, {
      class: "cp-margin__t--small cp-margin__b--small",
      icon: "education",
      "icon-size": "17",
      onClick: _ctx.returnBefore
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.titlePage), 1)
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createElementVNode("div", null, [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_loading, { key: 0 }))
        : (_openBlock(), _createBlock(_component_form_block, {
            key: 1,
            "type-action": "edit",
            onSave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.save($event))),
            onReturnBefore: _ctx.returnBefore
          }, null, 8, ["onReturnBefore"]))
    ])
  ]))
}