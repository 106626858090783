
import { defineComponent } from 'vue';
import TitleReturn from '@/components/TitleReturn.vue';
import Loading from '@/components/Loading.vue';
import FormBlock from './_form.vue';
import Form from '@/layouts/Form';
import TrainingCenterModule from '@/store/settings/trainingCenter';
import TrainingCenterEntityModule from '@/store/settings/trainingCenter/entity';

export default defineComponent({
  extends: Form as any,
  components: { Loading, TitleReturn, FormBlock },
  data: () => ({
    isLoading: true,
  }),
  computed: {
    titlePage(): string {
      return TrainingCenterModule.pageSettings.titleEdit;
    },
  },
  methods: {
    returnBefore() {
      this.$router.push({ name: 'training_center' });
    },
  },
  async mounted() {
    const id = this.$route.params.trainingCenterId;
    await TrainingCenterEntityModule.initEdit(id as string);

    this.isLoading = false;
  },
});
